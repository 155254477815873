import Head from "next/head";
import Img from "components/atoms/Img";
import Title from "components/atoms/Title";
import Button from "components/atoms/Button";

const Custom404 = () => {
  return (
    <div>
      <Head>
        <title>Trang không tồn tại | Gleads</title>
      </Head>
      <div className="relative h-screen overflow-hidden">
        <div className=" container absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
          <div className="m-auto text-center lg:col-10">
            <Img
              src="/assets/common/404bg.png"
              className="w-100 h-100 mx-auto"
              wrapper={{ className: "mb-6 lg:mb-10" }}
              width={920}
              height={418}
            />
            <div>
              <Title as="h1" othersClass={"mb-4"}>
                Trang không tồn tại
              </Title>
              <div className="mb-8 lg:mb-4">Vui lòng quay lại Trang chủ</div>
            </div>
            <Button as="a" rel="nofollow" href="/vi">
              Trang chủ
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Custom404;
